import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';

import { useAuth } from '../../auth/index.js';
import {
  CHECK_UNREAD_BERICHTEN,
  CREATE_BERICHTEN,
  GET_BERICHTEN,
  UPDATE_BERICHT,
} from '../../graphql/index.js';
import Button from '../common/Button.js';
import LoadingDivModal from '../common/LoadingDivModal';
import Page from '../common/page.js';
import PopupModal from '../common/PopupModal.js';
import TopTitle from '../common/TopTitle.js';
import { useStorage } from '../Helpers.js';
import SearchBox from '../SearchBox.js';
import { MessageFilter } from './filter.jsx';
import { Message } from './message.jsx';
import { NewMessageFormContent } from './newMessageFormContent.jsx';

const TitleAndButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5rem;
  padding-bottom: 0;
`;

const Container = styled.div`
  padding: 0.5rem;
`;

const NoMessages = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  padding: 2rem;
  font-size: 1.5rem;
  color: #666;
`;

const StyledSearchBox = styled(SearchBox)`
  margin: 1rem 0;
`;

/**
 * @property {MessageType} id - The id of the message type as defined in the backend
 */
export const messageTypes = [
  { id: 'toVulploeg', value: '@ vulploeg' },
  { id: 'toBedelingsploeg', value: '@ bedelingsploeg' },
  { id: 'toSupport', value: '@ support' },
];

export const adminMessageTypes = [
  { id: 'richtlijn', value: 'richtlijn' },
  { id: 'fromSupport', value: 'technische melding' },
];

export const allMessageTypes = messageTypes.concat(adminMessageTypes);

const initialValues = {
  richBody: '',
  title: '',
  type: '_default',
};

const NewMessageSchema = Yup.object().shape({
  type: Yup.string()
    .oneOf(
      allMessageTypes.map((type) => type.id),
      'Type is verplicht',
    )
    .required('Type is verplicht'),
  title: Yup.string().required('Titel is verplicht'),
  richBody: Yup.string().required('Bericht is verplicht'),
});

const MessagePage = () => {
  const { currentUser } = useAuth();
  const [searchString, setSearchString] = React.useState('');

  const [showNewMessageModal, setShowMessageModal] = React.useState(false);

  const [filter, setFilter] = useStorage(
    'berichtenFilter',
    {
      type: '_default',
      read: '_default',
    },
    'session',
  );

  const someFilterValueIsSet = Object.values(filter).some(
    (value) => value !== '_default',
  );

  const filterReadValue =
    filter.read === 'read'
      ? {
          readByUsers: currentUser?.id,
        }
      : filter.read === 'unread'
      ? {
          _or: [
            { readByUsers_null: true },
            { readByUsers_ncontains: currentUser?.id }, // THIS WILL NOT ALWAYS WORK, need to still filter client-side
          ],
        }
      : {};

  const searchValueFilter = searchString
    ? {
        _or: [
          { title_contains: searchString },
          { richBody_contains: searchString },
        ],
      }
    : {};

  const filterTypeValue = filter.type === '_default' ? undefined : filter.type;

  const { loading: loadingBerichten, data: dataBerichten } = useQuery(
    GET_BERICHTEN,
    {
      pollInterval: 5000,
      variables: {
        where: {
          _sort: 'created_at:DESC',
          type: filterTypeValue,
          _and: [filterReadValue, searchValueFilter],
        },
      },
    },
  );

  const [createMessage, { loading: loadingCreateMessage }] = useMutation(
    CREATE_BERICHTEN,
    {
      refetchQueries: [
        { query: GET_BERICHTEN },
        { query: CHECK_UNREAD_BERICHTEN },
      ],
      onError: (error) => {
        console.error(error);
      },
      onCompleted: () => {
        setShowMessageModal(false);
      },
    },
  );

  const [editMessage, { loading: loadingEditMessage }] = useMutation(
    UPDATE_BERICHT,
    {
      refetchQueries: [
        { query: GET_BERICHTEN },
        { query: CHECK_UNREAD_BERICHTEN },
      ],
      onError: (error) => {
        console.error(error);
      },
      onCompleted: () => {
        setShowMessageModal(false);
      },
    },
  );

  const [editingMessage, setEditingMessage] = React.useState(null);

  const handleSaveMessage = async (values, helpers) => {
    if (editingMessage) {
      await editMessage({
        variables: {
          id: editingMessage.id,
          input: {
            title: values.title,
            richBody: values.richBody,
          },
        },
      });
    } else {
      await createMessage({
        variables: {
          input: {
            title: values.title,
            richBody: values.richBody,
            type: values.type,
          },
        },
      });
    }
    helpers.resetForm();
  };

  // Filter messages based on the selected filter
  // this is done client-side because the backend has a bug when filtering on readByUsers_ncontains
  // bonus: by doing it client-side as well. toggling between read and unread is instant (when filtered)
  const filteredBerichten = dataBerichten?.berichtens.filter((messageData) => {
    if (filter.read === '_default') return true;
    if (filter.read === 'read') {
      return messageData.readByUsers.some(
        (user) => user.id === currentUser?.id,
      );
    }
    if (filter.read === 'unread') {
      return !messageData.readByUsers.some(
        (user) => user.id === currentUser?.id,
      );
    }
    return true;
  });

  const handleShowNewPopup = () => {
    setEditingMessage(null);
    setShowMessageModal(true);
  };

  const handleShowEditPopup = (messageData) => {
    setEditingMessage(messageData);
    setShowMessageModal(true);
  };

  return (
    <Page>
      <TitleAndButton>
        <TopTitle>Berichten</TopTitle>
        <Button primary onClick={handleShowNewPopup}>
          Nieuw Bericht 📝
        </Button>
        <MessageFilter filter={filter} setFilter={setFilter} />
        <StyledSearchBox
          setSearchString={setSearchString}
          searchString={searchString}
          loading={loadingBerichten}
        />
      </TitleAndButton>
      <Container>
        {loadingBerichten && <LoadingDivModal />}
        {filteredBerichten?.map((messageData) => (
          <Message
            key={JSON.stringify(messageData)}
            messageData={messageData}
            showEditPopup={handleShowEditPopup}
          />
        ))}
        {!loadingBerichten && filteredBerichten?.length === 0 && (
          <NoMessages>
            <div>Geen berichten gevonden</div>
            {someFilterValueIsSet && (
              <div>Pas eventueel uw filter aan, indien nodig</div>
            )}
          </NoMessages>
        )}
      </Container>
      <Formik
        enableReinitialize
        initialValues={editingMessage || initialValues}
        onSubmit={handleSaveMessage}
        validationSchema={NewMessageSchema}
        validateOnMount>
        <PopupModal
          width="90%"
          canClose
          visible={showNewMessageModal}
          setPopup={setShowMessageModal}>
          <NewMessageFormContent
            loading={loadingCreateMessage || loadingEditMessage}
            editing={Boolean(editingMessage)}
          />
        </PopupModal>
      </Formik>
    </Page>
  );
};

export default MessagePage;
