import React from 'react';
import { useMutation } from '@apollo/client';
import MDEditor from '@uiw/react-md-editor';
import { differenceInMinutes } from 'date-fns';
import styled, { css } from 'styled-components';

import { useAuth } from '../../auth';
import {
  CHECK_UNREAD_BERICHTEN,
  GET_BERICHTEN,
  SET_BERICHT_READ,
} from '../../graphql';
import ActivityIndicator from '../common/ActivityIndicator';
import Button from '../common/Button.js';
import { allMessageTypes } from './index.jsx';

const MessageRead = styled.div`
  justify-self: end;
  cursor: pointer;

  ${({ isRead }) =>
    !isRead &&
    css`
      font-weight: bolder;
      color: #e85a1e;
    `}
`;

const UnreadMessagesIndicator = styled.div`
  background-color: #e85a1e;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 100%;
  position: absolute;
  right: -0.5rem;
  top: -0.5rem;
`;

const MessageTypeWrapper = styled.div`
  ${({ isBedeling }) =>
    isBedeling
      ? css`
          display: none;
        `
      : css`
          display: flex;
          align-items: center;
        `}
`;

const MessageType = styled.span`
  background-color: #e5e5e5;
  border-radius: 999rem;
  padding: 0.25em 0.5em;
  color: #e85a1e;
  font-weight: bolder;
  ${({ isValid }) =>
    !isValid &&
    css`
      text-decoration: line-through;
    `}
`;

const MessageContent = styled.div.attrs({ 'data-color-mode': 'light' })`
  background-color: white;

  padding: 0.5rem;

  @media (min-width: 768px) {
    padding: 2rem;
  }

  @media (max-width: 560px) {
    & .wmde-markdown table {
      font-size: 0.75rem;
      & td {
        padding: 2px;
      }
    }
  }
`;

const TitleAndAuthor = styled.div``;

const MessageTitle = styled.h3`
  @media (max-width: 768px) {
    margin: 0;
  }
`;
const MessageAuthor = styled.div`
  color: #666;
`;

const DateAndTypeWrapper = styled.div`
  position: relative;
  ${({ isBedeling }) =>
    isBedeling
      ? css`
          text-align: center;
        `
      : css`
          justify-self: end;
        `};
`;

const MessageDate = styled.div`
  text-align: right;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const MessageEditedDate = styled.div`
  font-size: 0.75rem;
`;

const MessageWrapper = styled.div`
  ${({ isBedeling }) =>
    !isBedeling &&
    css`
      margin-bottom: 4rem;
      border: 1px solid #333;
      border-radius: 0.5rem;
      overflow: hidden;
    `};
`;

const MessageHeader = styled.div`
  display: grid;
  grid-template-columns: ${({ isBedeling }) =>
    isBedeling ? css`repeat(3,1fr)` : css`repeat(4, 1fr)`};
  gap: 1rem;
  align-items: center;
  background-color: #f5f5f5;
  padding: 0.5rem 2rem;

  @media (max-width: 768px) {
    grid-template-columns: ${({ isBedeling }) =>
      isBedeling ? css`repeat(2,1fr)` : css`repeat(2, 1fr)`};
    padding: 0.5rem 1rem;
  }
`;

export const Message = ({ messageData, isBedeling, showEditPopup }) => {
  const messageCreateDate = new Date(messageData.created_at);
  const messageEditDate = messageData.lastEditedAt
    ? new Date(messageData.lastEditedAt)
    : null;
  const messageWasEdited = messageEditDate
    ? differenceInMinutes(messageEditDate, messageCreateDate) > 0
    : false;
  const dateOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  const { currentUser, isAdmin } = useAuth();
  const isReadByCurrentUser = messageData.readByUsers.some(
    (user) => user.id == currentUser?.id, // explicit == because one is a string and the other an int
  );

  const [toggleRead, { loading }] = useMutation(SET_BERICHT_READ, {
    variables: { messageId: messageData.id },
    refetchQueries: [
      { query: CHECK_UNREAD_BERICHTEN },
      { query: GET_BERICHTEN },
    ],
    onError: (error) => {
      console.error(error);
    },
  });

  // a message is editable if currentUser is an admin and the message is a richtlijn
  const isEditable = isAdmin && messageData.type === 'richtlijn';

  const handleEditMessage = () => {
    if (!isEditable) return;
    if (!showEditPopup) return;
    showEditPopup(messageData);
  };

  return (
    <MessageWrapper isBedeling={isBedeling}>
      <MessageHeader isBedeling={isBedeling}>
        <TitleAndAuthor>
          <MessageTitle>{messageData.title}</MessageTitle>
          <MessageAuthor>van {messageData.fromUser.username}</MessageAuthor>
        </TitleAndAuthor>
        <DateAndTypeWrapper isBedeling={isBedeling}>
          <MessageDate>
            <div>
              {messageCreateDate?.toLocaleDateString('nl-BE', dateOptions)}
            </div>
            {messageWasEdited && (
              <MessageEditedDate>
                laatst aangepast op <br />
                {messageEditDate?.toLocaleDateString('nl-BE', dateOptions)}
              </MessageEditedDate>
            )}
          </MessageDate>
          {!isReadByCurrentUser && !isBedeling && <UnreadMessagesIndicator />}
        </DateAndTypeWrapper>
        <MessageTypeWrapper isBedeling={isBedeling}>
          <MessageType isValid={messageData.isValid}>
            {allMessageTypes.find((obj) => obj.id == messageData.type)?.value ??
              'Fout'}
          </MessageType>
          {isEditable && (
            <Button inline onClick={handleEditMessage}>
              ✏️
            </Button>
          )}
        </MessageTypeWrapper>
        <MessageRead
          canMarkAsRead={!isBedeling}
          isRead={isReadByCurrentUser}
          onClick={isBedeling ? null : () => toggleRead(isReadByCurrentUser)}>
          {loading ? (
            <ActivityIndicator />
          ) : isReadByCurrentUser ? (
            'Gelezen'
          ) : (
            'Ongelezen'
          )}
        </MessageRead>
      </MessageHeader>

      <MessageContent>
        <MDEditor.Markdown source={messageData.richBody} />
      </MessageContent>
    </MessageWrapper>
  );
};
